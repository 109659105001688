
import React from 'react'
import HorizontalAdPreview from './adSense/HorizontalAdPreview';
// import Midman from './Midman';
import News from './News';
import Trailer from './Trailer';

const Home = () => {
    return (
        <>
         {/* <Trailer /> */}
         <HorizontalAdPreview />
         {/* <Midman /> */}
         <HorizontalAdPreview />
         <News />
        </>
       
    )
}

export default Home;
