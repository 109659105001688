import Card from 'react-bootstrap/Card'
import { createTicket, uploadImageToImgBB } from '../../../helpers/APIHelper';
import React from 'react';
import Button  from 'react-bootstrap/Button'
import { Formik, Field } from 'formik'
import { Form, Alert, Row, Col, InputGroup  } from 'react-bootstrap'
import * as yup from "yup";
import ErrorModal from '../../modals/ErrorModal';
import Bubbles from '../../loading/Bubbles';
import { Input } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";


const CreateTicket = ( {setAuthentication} ) => {

    let history = useHistory();
    const [modalShow, setModalShow] = React.useState(false);
    const [errors, setErrors] = React.useState([]);
    const [isLoading, setLoading] = React.useState(false)
    const [isSuccess, setSuccess] = React.useState(false);

    const logoRef = React.useRef();

    const schema = yup.object().shape({
        amount: yup.number().required("Amount is required.").min(100, 'Minimum amount is 100'),
        referenceNumber: yup.string().required("Reference Number is required."),
        file: yup.mixed().required("Proof of Transaction is required.")
    });

    return (
        <Card className="ran-card">
            <Card.Header className="ran-card-header text-white">CREATE TICKET
            <Button onClick={() => history.goBack() }  variant="warning" className="text-dark" style={{float:"right"}}>
               <IoIosArrowBack /> BACK
            </Button>  
            </Card.Header>
            <Card.Body>
                <>
                            <Alert variant="warning" className="text-center">
                            <b>"TOPUP ARE PURELY VOLUNTARY, NON-COMPULSORY, NON-TRANSFERABLE AND NON-REFUNDABLE."</b> <br/>
                            <b><i>1 PHP = 1 E-POINT</i></b> <br/>
                            <b>MINIMUM AMOUNT IS 100 PHP</b> <br/><br/>
                            {/* <b>NO NEED TO INDICATE A MESSAGE WHEN SENDING YOUR PAYMENT.</b> <br/><br/> */}

                            ACCOUNT NUMBER: <b>0966 792 5456</b> <br/>
                            ACCOUNT NAME  : <b>M*R***S P.</b><br/>
                            <b>GCASH #1 </b><br/><br/>

                            {/* ACCOUNT NUMBER: <b>0905 326 2530</b> <br/>
                            ACCOUNT NAME  : <b>KE*****K CA*L S.</b><br/>
                            <b>GCASH #2 </b><br/><br/> */}

                            ACCOUNT NUMBER: <b>0927 450 0605</b> <br/>
                            ACCOUNT NAME  : <b>Z** MA*******E G.</b><br/>
                            <b>GCASH #2 </b><br/><br/>

                            {/* ACCOUNT NUMBER: <b>0917 109 3675</b> <br/>
                            ACCOUNT NAME  : <b>Z** MA*******E G.</b><br/>
                            <b>GCASH #2 </b><br/><br/> */}
                            
                            EMAIL: <b>zenmargarettegutierrez@gmail.com</b><br/>
                            <b>PAYPAL</b> <br/>
                            </Alert>
                            {
                                isSuccess &&
                                <Alert variant="info" >
                                    Ticket has been successfully created.
                                </Alert>
                            }
                             <Formik
                                initialValues={{
                                    amount: 0,
                                    message:"",
                                    file: "",
                                    fileName: "",
                                    referenceNumber: ""
                                }}
                                onSubmit={async (values, {resetForm}) => {
                                    setLoading(true)
                                    setSuccess(false)

                                    // Upload image to imgbb
                                    var image = await uploadImageToImgBB(values);
                                    if(image !== null){
                                        // assign image url.
                                        values.fileName = image.url;

                                        var result = await createTicket(values, setAuthentication);
                                        if(result !== null || result !== undefined){
                                            if(result.success) {
                                                setLoading(false)
                                                setSuccess(true)
                                                resetForm({
                                                    amount: 0,
                                                    message:"",
                                                    file: "",
                                                    fileName: "",
                                                    referenceNumber: ""
                                                })

                                                logoRef.current.value = null; //THIS RESETS THE FILE FIELD
                                            }
                                            else{
                                                // not found or bad request.
                                                if(result.status === 404 || result.status === 400){
                                                    setModalShow(true)
                                                    setErrors(result.errors)
                                                    setLoading(false)
                                                }
                                                // do nothing if unauthorize.
                                            }
                                        }
                                    }else{
                                        setLoading(false)
                                    }
                                }}
                                validationSchema={schema}>
                                {({
                                errors,
                                touched,
                                handleBlur,
                                handleChange,
                                isSubmitting,
                                values,
                                handleSubmit,
                                setFieldValue 
                                }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row>
                                            <Col>
                                                <Form.Group md="4" controlId="validateFormAmount" className="mt-2">
                                                    <Form.Label>Amount</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                        type="number"
                                                        placeholder="Amount"
                                                        name="amount"
                                                        value={values.amount}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.amount}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                        {errors.amount}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group md="4" controlId="validateFormProof" className="mt-2">
                                                    <Form.Label>Proof of Transaction</Form.Label>
                                                    <Input
                                                        className="form-control"
                                                        type="file"
                                                        name="file"
                                                        accept="image/*"
                                                        innerRef={logoRef}
                                                        onChange={(event) =>{
                                                            setFieldValue("file", event.target.files[0]);
                                                        }} >
                                                    </Input>
                                                    <div className="error" style={{color: "#dc3545"}}>{errors.file}</div>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group md="4" controlId="validateFormRef" className="mt-2">
                                                     <Form.Label>Reference Number (For faster processing, please input the correct reference number)</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <Form.Control
                                                        type="text"
                                                        placeholder="Reference Number"
                                                        name="referenceNumber"
                                                        value={values.referenceNumber}
                                                        onChange={handleChange}
                                                        isInvalid={!!errors.referenceNumber}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                        {errors.referenceNumber}
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                        </Form.Group>
                                        <Form.Group md="4" controlId="validateFormMessage">
                                            <Form.Label>Message</Form.Label>
                                            <Field className="form-control" name="message" placeholder="Message" as="textarea" value={values.message} onChange={handleChange}  />
                                        </Form.Group>
                                        {
                                            isLoading && 
                                            (
                                                <div className="mt-3">
                                                   <Bubbles />
                                                </div>
                                            )
                                        }
                                        <Form.Group  className="mt-4 mb-0">
                                            <Button  disabled={isLoading} type="submit" variant="warning" className="btn-danger text-white" style={{float:"right"}}>
                                                            {
                                                                isLoading ? 'PROCESSING' : 'SUBMIT TICKET'
                                                            }
                                            </Button>
                                        </Form.Group>
                                </Form>
                                )}
                            </Formik>
                            <ErrorModal
                                errors={errors}
                                show={modalShow}
                                onHide={() => setModalShow(false)}/>
                        </>
            </Card.Body>
        </Card>
    )
}

export default CreateTicket
